<template>
  <div>
    <page-title
      :heading="$t('erp.lang_importItemControllSystemData')"
      :subheading="$t('erp.lang_importItemControllSystemData')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <ImportItemControllSystemData></ImportItemControllSystemData>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import ImportItemControllSystemData from "../../../components/erp/settings/ImportItemControllSystemData.vue";

export default {
  components: {
    PageTitle,
    ImportItemControllSystemData,
  },
  data: () => ({
    icon: "pe-7s-upload icon-gradient bg-tempting-azure",
  }),
};
</script>
